/* eslint-disable complexity */
import { IonButton, IonInput, IonItem, IonLabel, useIonAlert, useIonToast } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'

import { useUpdateEmailMutation, useUpdatePasswordMutation } from '../../api/graphql'
import { useUser } from '../../contexts/User'
import { AppLayout } from '../../layout'

import classes from './Profile.module.scss'

export const Profile = (): JSX.Element => {
  const { user, setUser } = useUser()
  const [updateEmail] = useUpdateEmailMutation()
  const [updatePassword] = useUpdatePasswordMutation()

  const [email, setEmail] = useState<string>(user?.email || '')
  const [confirmedEmail, setConfirmedEmail] = useState<string>('')

  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmedPassword, setConfirmedPassword] = useState<string>('')

  const [confirmDelete, setConfirmDelete] = useState<string>('')

  const [changeEmail, setChangeEmail] = useState<boolean>(false)
  const [changePassword, setChangePassword] = useState<boolean>(false)
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false)

  const [presentAlert] = useIonAlert()
  const [presentToast] = useIonToast()

  useEffect(() => {
    if (user) setEmail(user.email)
  }, [user])

  const cancelPremium = (): void => {
    presentAlert({
      message: 'Wollen Sie wirklich zum Ende der Laufzeit Ihren Premium Account kündigen?',
      buttons: [
        'Abbrechen',
        {
          text: 'Kündigen',
          handler: _d => {
            updateEmail({ variables: { newEmail: confirmedEmail } }).then(() => {
              if (user) setUser({ ...user, email: confirmedEmail })
              setEmail(confirmedEmail)
              setConfirmedEmail('')
            })
          },
        },
      ],
      onDidDismiss: _e => {
        setChangeEmail(!changeEmail)
      },
    })
  }

  const showEmailConfirmationAlert = (): void => {
    presentAlert({
      message: 'Bist Du sicher, dass Du Deine E-Mail-Adresse und damit auch Deinen Login ändern möchtest?',
      buttons: [
        'Abbrechen',
        {
          text: 'Ok',
          handler: _d => {
            updateEmail({ variables: { newEmail: confirmedEmail } }).then(() => {
              if (user) setUser({ ...user, email: confirmedEmail })
              setEmail(confirmedEmail)
              setConfirmedEmail('')
            })
          },
        },
      ],
      onDidDismiss: _e => {
        setChangeEmail(!changeEmail)
      },
    })
  }

  const showEmailNotMatchingAlert = (): void => {
    presentAlert({
      message: 'Die angegebenen E-Mail-Adressen stimmen nicht überein.',
      buttons: ['Ok'],
    })
  }

  const showPasswordNotMatchingAlert = (): void => {
    presentAlert({
      header: 'Hinweis',
      message: 'Die Eingaben zum neuen Passwort stimmen nicht überein.',
      buttons: ['Ok'],
    })
  }

  const showNewPasswordSavedAlert = (): void => {
    presentAlert({
      message: 'Dein Passwort wurde erfolgreich geändert.',
      buttons: ['Ok'],
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  const showDeleteAccountAlert = (): void => {
    presentAlert({
      header: 'ACHTUNG',
      message:
        'Bist Du sicher, dass Du Deinen Account sowie die zugehörigen Daten unwiderruflich löschen möchtest? Dann tippe in das nachfolgende Eingabefeld: LÖSCHEN',
      inputs: [
        {
          name: 'confirmDelete',
          type: 'text',
          placeholder: 'LÖSCHEN',
          value: confirmDelete,
        },
      ],
      buttons: [
        'Abbrechen',
        {
          text: 'Ok',
          handler: _e => {
            setConfirmDelete(_e.confirmDelete)
          },
        },
      ],
      onDidDismiss: _e => {
        setDeleteAccount(!deleteAccount)
      },
    })
  }

  const showDeleteAccountFailedAlert = useCallback((): void => {
    presentAlert({
      message: 'Die Eingabe zur Löschung Deines Kontos stimmt nicht überein!',
      buttons: ['Ok'],
      onDidDismiss: _e => {
        setConfirmDelete('')
      },
    })
  }, [presentAlert])

  useEffect(() => {
    if (confirmDelete !== '' && confirmDelete !== 'LÖSCHEN') showDeleteAccountFailedAlert()
    // TODO: add function to delete account when confirmDelete === 'LÖSCHEN'
    if (confirmDelete === 'LÖSCHEN') console.log('gelöscht')
  }, [confirmDelete, showDeleteAccountFailedAlert])

  const handleClickChangeEmail = (): void => {
    setChangeEmail(!changeEmail)
    if (changeEmail) setEmail(user?.email || '')
    setChangePassword(false)
  }

  const handleClickSaveEmail = (): void => {
    if (email === confirmedEmail) {
      showEmailConfirmationAlert()
    } else {
      showEmailNotMatchingAlert()
    }
  }

  const handleClickChangePassword = (): void => {
    setChangePassword(!changePassword)
    setChangeEmail(false)
  }

  const handleClickSavePassword = (): void => {
    if (newPassword === confirmedPassword && newPassword !== '') {
      if (user) {
        updatePassword({ variables: { newPassword } })
          .then(() => {
            setNewPassword('')
            setConfirmedPassword('')
            showNewPasswordSavedAlert()
            setChangePassword(false)
          })
          .catch(e => {
            presentToast('Hoppla! Es ist etwas schief gelaufen, versuche es später nochmal.', 3000)
            console.error(e)
          })
      }
    } else {
      showPasswordNotMatchingAlert()
    }
  }

  return (
    <AppLayout variant="menu" bgColor="white">
      <div className={classes.container}>
        <h1 className={classes.heading}>Account</h1>

        <div className={classes.emailContainer}>
          <div className={classes.emailInputContainer}>
            <IonItem className={classes.inputItem}>
              <IonLabel position="fixed">E-Mail</IonLabel>
              <IonInput
                disabled={!changeEmail || changePassword}
                inputmode="email"
                placeholder="Neue E-Mail"
                value={email}
                onIonChange={e => setEmail(e.detail.value!)}
              />
            </IonItem>

            {changeEmail && !changePassword && (
              <IonItem className={classes.inputItem}>
                <IonInput
                  disabled={!changeEmail}
                  inputmode="email"
                  placeholder="Neue E-Mail bestätigen"
                  value={confirmedEmail}
                  onIonChange={e => setConfirmedEmail(e.detail.value!)}
                />
              </IonItem>
            )}
          </div>

          {changeEmail && (
            <IonButton className={classes.saveEmailButton} expand="block" color="turquoise" onClick={handleClickSaveEmail}>
              Speichern
            </IonButton>
          )}
          <IonButton className={classes.emailButton} expand="block" color="turquoise" onClick={handleClickChangeEmail}>
            {!changeEmail ? 'E-Mail ändern' : 'Abbrechen'}
          </IonButton>
        </div>

        <IonItem className={classes.inputItem}>
          <IonLabel position="fixed">Passwort</IonLabel>
          <IonInput disabled inputmode="text" value="**********" />
        </IonItem>

        <div className={classes.passwordContainer}>
          <div>
            {!changeEmail && (
              <div className={classes.passwordInputContainer}>
                {changePassword && (
                  <>
                    <IonItem className={classes.inputItem}>
                      <IonInput
                        type="password"
                        inputmode="text"
                        minlength={8}
                        placeholder="Neues Passwort"
                        autocomplete="new-password"
                        value={newPassword}
                        onIonChange={e => setNewPassword(e.detail.value!)}
                      />
                    </IonItem>
                    <IonItem className={classes.inputItem}>
                      <IonInput
                        type="password"
                        inputmode="text"
                        minlength={8}
                        placeholder="Neues Passwort bestätigen"
                        autocomplete="new-password"
                        value={confirmedPassword}
                        onIonChange={e => setConfirmedPassword(e.detail.value!)}
                      />
                    </IonItem>
                  </>
                )}
              </div>
            )}
          </div>

          {changePassword && (
            <IonButton className={classes.savePasswordButton} expand="block" color="turquoise" onClick={handleClickSavePassword}>
              Speichern
            </IonButton>
          )}
          <IonButton className={classes.passwordButton} expand="block" color="turquoise" onClick={handleClickChangePassword}>
            {!changePassword ? 'Passwort ändern' : 'Abbrechen'}
          </IonButton>
        </div>

        <hr />
        <h1 className={classes.heading}>Abonnement</h1>
        <IonItem className={classes.inputItem}>
          <IonLabel position="fixed">Account</IonLabel>
          <IonInput readonly={true} inputmode="text" value={user?.premium ? 'Premium' : 'Free'} />
        </IonItem>

        {user?.payments && user?.payments[0] && (
          <>
            <IonItem className={classes.inputItem}>
              <IonLabel position="fixed">Start</IonLabel>
              <IonInput
                className={classes.input}
                readonly={true}
                inputmode="text"
                value={new Date(user?.payments[0]?.subscription_start).toLocaleDateString()}
              />
            </IonItem>
            <IonItem className={classes.inputItem}>
              <IonLabel position="fixed">Endet</IonLabel>
              <IonInput
                readonly={true}
                inputmode="text"
                value={new Date(user?.payments[0]?.subscription_end).toLocaleDateString()}
              />
            </IonItem>
            {user?.payments[0].cancel_at_period_end ? (
              <IonButton className={classes.cancelButton} expand="block" color="warning" disabled>
                Abo zum Ende der Laufzeit gekündigt
              </IonButton>
            ) : (
              <>
                <IonItem className={classes.inputItem}>
                  <IonLabel position="fixed"> </IonLabel>
                  <p>Abo verlängert sich automatisch</p>
                </IonItem>
                <IonButton className={classes.cancelButton} expand="block" color="turquoise" onClick={cancelPremium}>
                  Premium Abo kündigen
                </IonButton>
              </>
            )}
          </>
        )}

        {console.log(user)}

        {/* <IonButton
          className={classes.deleteAccountButton}
          expand="block"
          fill="outline"
          color="danger"
          onClick={showDeleteAccountAlert}
        >
          Konto löschen
        </IonButton> */}
      </div>
    </AppLayout>
  )
}
