import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { IonApp, IonRouterOutlet, IonSplitPane, useIonRouter } from '@ionic/react'
import { Route } from 'react-router-dom'

import Menu from './components/Menu'
import { BodyPartsProvider } from './contexts/BodyPartsProvider'
// eslint-disable-next-line import/order
import { useStorage } from './contexts/Storage'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
/* import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css"; */

/* Theme variables */
import './theme/variables.scss'
import './app.scss'

import ROUTES from './routes'

const App: React.FC = () => {
  const { storage } = useStorage()
  const router = useIonRouter()

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql` || 'http://localhost:1337/graphql',
  })

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await storage?.get('token')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  // redirect to /intro when intro havnt been seen and route is not /intro at this moment
  /*   useEffect(() => {
    const { pathname } = router.routeInfo
    if (pathname !== '/intro') {
      const checkIfIntroHasBeenSeen = async (): Promise<boolean> => {
        const value = await storage?.get('skipIntro')
        return value !== null
      }

      checkIfIntroHasBeenSeen().then(skipIntro => {
        if (!skipIntro) {
          // eslint-disable-next-line fp/no-mutating-methods
          router.push('/intro')
        }
      })
    }
  }, [router, storage]) */

  return (
    <ApolloProvider client={client}>
      <BodyPartsProvider>
        <IonApp id="app">
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              {ROUTES.map(({ path, exact, Component }, index) => (
                <Route path={path} exact={exact} key={index}>
                  <Component />
                </Route>
              ))}
            </IonRouterOutlet>
          </IonSplitPane>
        </IonApp>
      </BodyPartsProvider>
    </ApolloProvider>
  )
}

export default App
