import {
  Home,
  Introduction,
  Login,
  Register,
  About,
  BodyPart,
  Issue,
  Exercise,
  Profile,
  Favorites,
  Checkout,
  CheckoutSuccess,
  ForgotPassword,
} from './pages'

interface Route {
  name: string
  path: string
  exact?: boolean
  authorized?: boolean
  Component: React.FC
}

const ROUTES: Array<Route> = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    authorized: true,
    Component: Home,
  },
  {
    name: 'Intro',
    path: '/intro',
    authorized: true,
    Component: Introduction,
  },
  {
    name: 'About',
    path: '/about',
    authorized: true,
    Component: About,
  },
  {
    name: 'Login',
    path: '/login',
    Component: Login,
  },
  {
    name: 'Register',
    path: '/register',
    Component: Register,
  },
  {
    name: 'Profile',
    path: '/profile',
    authorized: true,
    Component: Profile,
  },
  {
    name: 'ForgotPassword',
    path: '/forgot-password',
    Component: ForgotPassword,
  },
  {
    name: 'BodyPart',
    path: '/bodyparts',
    authorized: true,
    Component: BodyPart,
  },
  {
    name: 'Issue',
    path: '/issues/:id',
    authorized: true,
    Component: Issue,
  },
  {
    name: 'Exercise',
    path: '/exercises/:id',
    authorized: true,
    Component: Exercise,
  },
  {
    name: 'Favorites',
    path: '/favorites',
    authorized: true,
    Component: Favorites,
  },
  {
    name: 'Success',
    path: '/checkout-success',
    authorized: true,
    Component: CheckoutSuccess,
  },
  {
    name: 'Checkout',
    path: '/checkout/:period',
    exact: true,
    authorized: true,
    Component: Checkout,
  },
]

export default ROUTES
