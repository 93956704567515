import { Link } from 'react-router-dom'

import { FetchStatus } from '../../components/FetchStatus'
import { useBodyParts } from '../../contexts/BodyPartsProvider'
import { AppLayout } from '../../layout'

import classes from './Home.module.scss'

export const Home = (): JSX.Element => {
  const { bodyParts, loading, error } = useBodyParts()

  return (
    <AppLayout variant="menu" iconColor="light" bgColor="dark-turquoise">
      <div className={classes.container}>
        <div className={classes.title}>Wo hast du Schmerzen?</div>
        {bodyParts?.map(
          bp =>
            bp && (
              <Link key={bp.id} to={`/bodyparts?id=${bp.id}`} className={classes.bodyPartItem}>
                <div lang="de">{bp.title}</div>
              </Link>
            ) // eslint-disable-line comma-dangle
        )}
      </div>
      <FetchStatus loading={loading} error={error} />
    </AppLayout>
  )
}
