import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSpinner,
  useIonToast,
} from '@ionic/react'
import cn from 'classnames'
import { logInOutline, checkmarkCircle, arrowForwardOutline } from 'ionicons/icons'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useRegisterMutation } from '../../api/graphql'
import { useUser, ContextUser } from '../../contexts/User'

import classes from './Register.module.scss'

export const Register: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [checkedAgb, setCheckedAgb] = useState<boolean>()
  const [present] = useIonToast()
  const [success, setSuccess] = useState<boolean>(false)
  const { login } = useUser()

  const [registerMutation, { loading }] = useRegisterMutation({
    variables: {
      username: email!,
      email: email!,
      password: email!,
    },
  })

  const register = (): void => {
    registerMutation()
      .then(async ({ data }) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        await login(data?.register.jwt as string, data?.register.user as ContextUser)
        setSuccess(true)
      })
      .catch(e => {
        present('Etwas stimmt mit deinen Daten nicht', 3000)
        console.error(e)
      })
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={classes.bg}>
          <div className={classes.spacer} />
          <div className={classes.body}>
            {!success ? (
              <>
                <h1>
                  Erstelle deinen
                  <br />
                  kostenlosen Account
                </h1>
                <p className={classes.text}>
                  Oder hast du schon einen Account? <Link to="/login">Einloggen</Link>
                </p>
                <div>
                  <IonItem className={classes.item} color="none">
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      disabled={loading}
                      type="email"
                      value={email}
                      onIonChange={e => setEmail(e.detail.value!)}
                      required
                    />
                  </IonItem>
                  <IonItem className={classes.item} color="none">
                    <IonLabel position="floating">Passwort</IonLabel>
                    <IonInput
                      disabled={loading}
                      type="password"
                      value={password}
                      onIonChange={e => setPassword(e.detail.value!)}
                      minlength={6}
                      required
                    />
                  </IonItem>
                  <IonItem lines="none" className={cn(classes.item, classes.termsWrapper)} color="none">
                    <IonCheckbox checked={checkedAgb} onIonChange={e => setCheckedAgb(e.detail.checked)} disabled={loading} />
                    <IonLabel>
                      Ich habe die AGB gelesen und <br />
                      bin damit einverstanden.
                    </IonLabel>
                  </IonItem>
                  <IonRouterLink
                    href="https://bielo.eu/geschaftsbedingungen/"
                    target="_blank"
                    className={classes.termsSubWrapper}
                  >
                    Die AGB in extenem Fenster auf bielo.eu öffnen
                  </IonRouterLink>{' '}
                </div>
                <IonButton
                  color="primary"
                  className={classes.btn}
                  disabled={!email || !password || !checkedAgb || loading}
                  onClick={() => register()}
                >
                  {loading ? (
                    <IonSpinner name="crescent" />
                  ) : (
                    <>
                      <IonIcon slot="icon-only" icon={logInOutline} className={classes.icon} />
                      <span className={classes.btnText}>Kostenlos registrieren</span>
                    </>
                  )}
                </IonButton>
              </>
            ) : (
              <div className={classes.successPage}>
                <IonIcon icon={checkmarkCircle} className={classes.successIcon} />
                <h1 className={classes.heading}>Willkommen</h1>
                <h2 className={classes.subheading}>Schön, dass du dabei bist!</h2>
                <IonRouterLink href="/intro">
                  <IonButton color="primary" className={classes.btn}>
                    <>
                      <IonIcon slot="icon-only" icon={arrowForwardOutline} className={classes.icon} />
                      <span className={classes.btnText}>Zu den Übungen</span>
                    </>
                  </IonButton>
                </IonRouterLink>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
