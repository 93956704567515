import { IonIcon, useIonModal } from '@ionic/react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { Premium } from '../..'
import { Issues } from '../../../api/graphql'
import premiumIcon from '../../../common/icons/locked.svg'
import { useUser } from '../../../contexts/User'

import classes from './IssueListItem.module.scss'

type IssueListItemProps = Pick<Issues, 'id' | 'title' | 'premium'> & { countOfExercises: number | undefined; locked: boolean }

export const IssueListItem: React.FC<IssueListItemProps> = ({ id, title, premium, countOfExercises, locked = true }) => {
  const [present, dismiss] = useIonModal(Premium, {
    onDismiss: () => dismiss(),
    onGetPremium: () => console.log('PAYMENT'),
  })

  const contentUnlocked = (premium && !locked) || !premium

  return contentUnlocked ? (
    <Link className={classes.link} to={`/issues/${id}`}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <span className={classes.title}>{title}</span>
          {(!!countOfExercises || countOfExercises === 0) && (
            <span className={classes.count}>{`${countOfExercises} Übungen`}</span>
          )}
        </div>
      </div>
    </Link>
  ) : (
    <div className={classes.container} onClick={() => present()} onKeyPress={() => present()} role="button" tabIndex={0}>
      <div className={cn(classes.textContainer, classes.issueLocked)}>
        <span className={classes.title}>{title}</span>
        {(!!countOfExercises || countOfExercises === 0) && <span className={classes.count}>{`${countOfExercises} Übungen`}</span>}
      </div>
      <IonIcon className={classes.iconPremium} src={premiumIcon} />
    </div>
  )
}
