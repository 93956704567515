/* eslint-disable fp/no-mutation, fp/no-let, @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { useMemo } from 'react'

let apolloClient: any

export const createApolloClient = (): any =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createHttpLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql` || 'http://localhost:1337/graphql',
    }),
    cache: new InMemoryCache(),
  })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    _apolloClient.cache.restore({ ...existingCache, ...(initialState as any) })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export const useApollo = (initialState: any): any => {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
