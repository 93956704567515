import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel } from '@ionic/react'
import { Link } from 'react-router-dom'

import { LoginMutation } from '../../../api/graphql'
import arrow from '../../../common/icons/arrow.svg'
import { IssueListItem } from '../../BodyPart/components/IssueListItem'

import classes from './FavoritesListItem.module.scss'

type Unpacked<T> = T extends Array<infer U> ? U : T
type FavType = 'issue' | 'exercise'

type FavoritesListItemProps = Unpacked<LoginMutation['login']['user']['favorites']> & {
  onFavoriteDelete: (id: string) => void
  type: FavType
}

export const FavoritesListItem: React.FC<FavoritesListItemProps> = ({
  id,
  title: exerciseTitle,
  issues,
  onFavoriteDelete,
  type,
}) => (
  <Link className={classes.link} to={`${type === 'issue' ? '/issues' : '/exercises'}/${id}`}>
    <IonItemSliding>
      <IonItem className={classes.item} lines="none">
        <IonLabel>
          <div className={classes.contentContainer}>
            <div className={classes.titleContainer}>
              <span className={classes.exerciseTitle}>{exerciseTitle}</span>
              <img className={classes.iconArrow} src={arrow} alt="Pfeil" />
            </div>
            {issues &&
              issues.map(i => (
                <span key={i?.id} className={classes.issueTitle}>
                  {i?.title}
                </span>
              ))}
          </div>
        </IonLabel>
      </IonItem>
      <IonItemOptions className={classes.itemOptions} side="end">
        <IonItemOption className={classes.itemOption} onClick={() => onFavoriteDelete(id)}>
          Löschen
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  </Link>
)
