import './SafeArea.scss'
import cn from 'classnames'

interface SafeAreaProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * @default true
   */
  top?: boolean;
  className?: string;
}

export const SafeArea: React.FC<SafeAreaProps> = ({
  children,
  top = true,
  className,
  ...props
}) => (
  <div
    {...props}
    className={cn('safe-area-wrapper', className, {
      'safe-area-wrapper--no-top': !top,
    })}
  >
    {children}
  </div>
)
