import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonButton,
  IonIcon,
  IonRouterLink,
  IonProgressBar,
  IonSpinner,
  useIonRouter,
  useIonToast,
} from '@ionic/react'
import cn from 'classnames'
import { logInOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'

import { useLoginMutation } from '../../api/graphql'
import { useUser } from '../../contexts/User'

import classes from './Login.module.scss'

export const Login: React.FC = () => {
  const router = useIonRouter()
  const [present] = useIonToast()
  const { setJwt, setUser, login } = useUser()
  const [inputEmail, setInputEmail] = useState<string>('')
  const [inputPassword, setInputPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [loginMutation, { data, loading: gqlLoading }] = useLoginMutation({
    variables: {
      identifier: inputEmail,
      password: inputPassword,
    },
  })

  useEffect(() => {
    if (gqlLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [gqlLoading])

  useEffect(() => {
    if (data) {
      const { login: authData } = data

      if (authData.jwt && authData.user) {
        login(authData.jwt, authData.user)
        router.push('/') // eslint-disable-line fp/no-mutating-methods
      }
    }
  }, [data, setJwt, setUser]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = (email: string, password: string): void => {
    setLoading(true)
    loginMutation({ variables: { identifier: email, password } })
      .then(result => {
        if (result && result.data) {
          const { login: authData } = result.data

          if (authData.jwt && authData.user) {
            setJwt(authData.jwt)
            setUser(authData.user)
            router.push('/') // eslint-disable-line fp/no-mutating-methods
          } else {
            present('Logindaten fehlerhaft')
          }
        }
      })
      .catch(e => {
        present('Bitte überprüfen Sie Ihre Zugangsdaten.', 3000)
        console.error(e)
      })
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={classes.bg}>
          {loading && <IonProgressBar type="indeterminate" className={classes.loadingBar} />}
          <div className={classes.logoWrapper}>
            <img src="/assets/icon/icon-w.png" alt="logo" width="100" />
          </div>
          <div>
            <h1 className={classes.heading}>
              Schmerzen lindern
              <br /> mit Yoga
            </h1>
            <p className={classes.subheading}>Die offizielle App zum Bestseller-Buch</p>
          </div>
          <div className={classes.inputArea}>
            <IonItem color="primary">
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                inputmode="email"
                value={inputEmail}
                onIonChange={e => setInputEmail(e.detail.value!)}
                disabled={loading}
              />
            </IonItem>
            <IonItem color="primary">
              <IonLabel position="stacked">Passwort</IonLabel>
              <IonInput
                type="password"
                value={inputPassword}
                onIonChange={e => setInputPassword(e.detail.value!)}
                disabled={loading}
              />
            </IonItem>
          </div>
          <div className={classes.btnArea}>
            <IonButton
              onClick={() => {
                handleLogin(inputEmail, inputPassword)
              }}
              color="secondary"
              className={classes.btn}
            >
              {loading ? (
                <IonSpinner name="crescent" />
              ) : (
                <>
                  <IonIcon color="light" slot="icon-only" icon={logInOutline} />
                  <span className={classes.btnText}>Login</span>
                </>
              )}
            </IonButton>
            <IonRouterLink href="/register">
              <IonButton color="primary" className={cn(classes.btn, classes.btnRegister)}>
                <span className={classes.btnText}>Kostenlos Registrieren</span>
              </IonButton>
            </IonRouterLink>
            <div className={classes.linkWrapper}>
              <IonRouterLink href="/forgot-password" className={classes.passwordForgetLink}>
                Passwort vergessen?
              </IonRouterLink>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
