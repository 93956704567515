import React, { useEffect, useState } from 'react'

import { AboutQuery } from '../../api/graphql'
import { getAbout } from '../../api/graphql/about'
import { AppLayout } from '../../layout'

import classes from './About.module.scss'

export const About = (): JSX.Element => {
  const [data, setData] = useState<AboutQuery['about']>()

  const fetchData = async (): Promise<void> => {
    const { data: result } = await getAbout()
    setData(result.about)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return data ? (
    <AppLayout variant="menu">
      <div className={classes.container}>
        <h1>{data.aboutTitle || ''}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.aboutText || '' }} />
      </div>
    </AppLayout>
  ) : (
    <>loading...</>
  )
}
