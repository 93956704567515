import { ApolloError } from '@apollo/client'
import { IonSpinner, IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'

import classes from './FetchStatus.module.scss'

export const FetchStatus = ({ loading, error }: { loading?: boolean; error?: ApolloError }): JSX.Element | null => {
  const [errorToast, setErrorToast] = useState(false)

  useEffect(() => {
    if (error) {
      console.error(error)
      setErrorToast(true)
    }
  }, [error])

  return loading || !!error ? (
    <div className={classes.fetchStatus}>
      {loading && <IonSpinner color="light" />}
      {!!error && (
        <IonToast
          isOpen={errorToast}
          onDidDismiss={() => setErrorToast(false)}
          message="Hoppla! Da ist etwas schief gelaufen"
          duration={5000}
        />
      )}
    </div>
  ) : null
}
