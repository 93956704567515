/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-constant-condition */
import { IonButton, useIonAlert } from '@ionic/react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'

import { CheckoutSuccess } from '..'
import { useUser } from '../../contexts/User'

import classes from './Checkout.module.scss'

export const CheckoutForm: React.FC = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [status, setStatus] = useState<'success' | 'error'>()
  const [disabled, setDisabled] = useState<boolean>(false)
  const [showAlert] = useIonAlert()
  const { setUser } = useUser()

  const setPremium = (): void => {
    setUser((currentUser: any) => ({
      ...currentUser,
      role: {
        type: 'premium',
      },
    }))
  }

  const handleSubmit = async (event: any): Promise<any> => {
    event.preventDefault()
    setDisabled(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const { error } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      console.log(error)
      showAlert({
        header: 'Zahlung fehlgeschlagen',
        message: `${error.message}`,
        buttons: ['Angaben ändern'],
      })
      setDisabled(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setPremium()
      setStatus('success')
    }
  }

  return (
    <>
      {status === 'success' && <CheckoutSuccess />}
      {!status && (
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <IonButton type="submit" className={classes.submitButton} disabled={disabled}>
            Zahlungspflichtig abschließen
          </IonButton>
        </form>
      )}
    </>
  )
}
