import { StatusBar, Style } from '@capacitor/status-bar'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonContent,
  isPlatform,
  IonBackButton,
  IonButton,
  IonIcon,
  useIonRouter,
} from '@ionic/react'
import { homeOutline } from 'ionicons/icons'
import React, { useEffect } from 'react'

import classes from './AppLayout.module.scss'

interface AppLayoutProps {
  variant?: 'menu' | 'back'
  bgColor?: string
  iconColor?: string
  statusBarColor?: 'light' | 'dark'
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  variant = 'menu',
  bgColor,
  statusBarColor = 'dark',
  children,
  iconColor = 'dark',
}) => {
  useEffect(() => {
    if (isPlatform('capacitor')) {
      if (statusBarColor === 'light') {
        StatusBar.setStyle({ style: Style.Dark })
      } else {
        StatusBar.setStyle({ style: Style.Light })
      }
    }
  }, [statusBarColor])

  const router = useIonRouter()

  const renderButton = (variant: AppLayoutProps['variant']): JSX.Element => {
    switch (variant) {
      case 'menu':
        return <IonMenuButton color={iconColor} />
      case 'back':
        return <IonBackButton color={iconColor} text="" />
      default:
        return <IonMenuButton color={iconColor} />
    }
  }

  const handleClickOverviewIcon = (): void => {
    // eslint-disable-next-line fp/no-mutating-methods
    router.push('/')
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={classes.toolbar} color={bgColor}>
          <IonButtons className={classes.button} slot="start">
            {renderButton(variant)}
          </IonButtons>
          <IonButtons className={classes.button} slot="end">
            <IonButton onClick={handleClickOverviewIcon}>
              {router.routeInfo.pathname !== '/' && (
                <IonIcon icon={homeOutline} color={iconColor} className={classes.overviewButton} />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className={classes.ionContent} color={bgColor}>
        <div>{children}</div>
      </IonContent>
    </IonPage>
  )
}
