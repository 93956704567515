/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-constant-condition */
import { IonIcon } from '@ionic/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { syncCircleOutline as loadingIcon } from 'ionicons/icons'
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { useCreatePaymentIntentMutation } from '../../api/graphql'
import { AppLayout } from '../../layout'

import classes from './Checkout.module.scss'
import { CheckoutForm } from './CheckoutForm'

const stripePromise = loadStripe(
  // eslint-disable-next-line comma-dangle
  'pk_test_51JxCObDddDdrf0ahAQ1vWiP3cQirjxfOAPflZyG7NPJSjvXKV6DkLdar0UfQvXdn7legwKK2iRa7KMPg146xyyg700l546ZUcu'
)

export const Checkout: React.FC = () => {
  const [createPaymentIntent, { data, loading }] = useCreatePaymentIntentMutation()
  const { period }: { period: 'month' | 'year' } = useParams()

  useEffect(() => {
    if (!data && period) {
      if (period === 'month') {
        // month
        createPaymentIntent({ variables: { priceId: 'price_1KWhq9DddDdrf0ahC3rBk38O' } })
      } else if (period === 'year') {
        // year
        createPaymentIntent({ variables: { priceId: 'price_1KWhqRDddDdrf0ahHBbga8Vf' } })
      }
    }
  }, [])

  const options = {
    clientSecret: data?.createPaymentIntent.clientSecret,
    variables: {
      colorPrimary: '#01998a',
    },
  }

  return (
    <AppLayout variant="back" bgColor="white">
      <div className={classes.checkoutWraper}>
        {!loading && stripePromise && data ? (
          <div>
            <Elements stripe={stripePromise} options={options}>
              <CheckoutForm />
            </Elements>
          </div>
        ) : (
          <div className={classes.loadingIconWrapper}>
            <IonIcon className={classes.loadingIcon} icon={loadingIcon} />
          </div>
        )}
      </div>
    </AppLayout>
  )
}
