/* eslint-disable complexity */
/* eslint-disable jsx-a11y/media-has-caption */
import { IonIcon } from '@ionic/react'
import { heart, heartOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

import {
  useAddFavoriteToMeMutation,
  useExerciseQuery,
  useExercisesListQuery,
  useRemoveFavoriteFromMeMutation,
} from '../../api/graphql'
import arrow from '../../common/icons/arrow.svg'
import { FetchStatus } from '../../components/FetchStatus'
import { useUser } from '../../contexts/User'
import { AppLayout } from '../../layout'

import classes from './Exercise.module.scss'

export const Exercise: React.FC = () => {
  const param: { id: string } = useParams()
  const { user, setUser } = useUser()
  const { data, loading, error } = useExerciseQuery({ variables: { id: param.id } })
  const { data: exerciseList } = useExercisesListQuery()
  const [addFavoriteToMeMutation] = useAddFavoriteToMeMutation()
  const [removeFavoriteFromMeMutation] = useRemoveFavoriteFromMeMutation()

  const exercises = exerciseList?.exercises?.map(x => x && x.id)
  const exercisesLastIndex = exercises && exercises.length - 1
  const currentExercise = exercises?.indexOf(param.id)
  const nextExercise = currentExercise !== undefined && currentExercise !== exercisesLastIndex && exercises?.[currentExercise + 1]

  const API_URL = process.env.REACT_APP_API_URL

  const isExerciseFavorite = (id: string): boolean => {
    if (user?.favorites) {
      return user.favorites.map(favorite => favorite?.id).includes(id)
    } else {
      return false
    }
  }

  const handleClickFavorite = (id: string): void => {
    if (isExerciseFavorite(id)) {
      removeFavoriteFromMeMutation({ variables: { id, type: 'exercise' } }).then(result => {
        if (user && result.data) setUser({ ...user, favorites: result.data.removeFavoriteFromMe.favorites })
      })
    } else {
      addFavoriteToMeMutation({ variables: { id, type: 'exercise' } }).then(result => {
        if (user && result.data) setUser({ ...user, favorites: result.data.addFavoriteToMe.favorites })
      })
    }
  }

  const isExerciseImageSliderLoaded = (): boolean =>
    !!(data && data.exercises && data.exercises[0]?.imageSlider && data.exercises[0]?.imageSlider.length > 0)

  // TODO: redirect to payment page when user is locked (except exercise is not premium) & use user context for locked status

  const history = useHistory()

  const userIsPremium = user?.premium

  const [partOfPremium, setPartOfPremium] = useState<boolean>(false)

  useEffect(() => {
    // this checks if the exercise is in any issue included, which is premium (and should not be shown for free users)
    /* if (data && data.exercises) setPartOfPremium(data.exercises[0]?.issues?.some(exercise => exercise?.premium) || false) */
    if (partOfPremium && !userIsPremium) history.replace('/')
  }, [data, history, userIsPremium, partOfPremium])

  return (
    <AppLayout variant="back" bgColor="turquoise" iconColor="light" statusBarColor="light">
      {data && data.exercises && data.exercises.length > 0 && (
        <>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{data.exercises[0]?.title}</div>
            <IonIcon
              className={classes.favoriteIcon}
              icon={isExerciseFavorite(param.id) ? heart : heartOutline}
              color="light"
              onClick={() => handleClickFavorite(param.id)}
            />
          </div>
          {isExerciseImageSliderLoaded() && (
            <Carousel
              className="exercise-carousel-wrapper"
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              autoPlay={false}
            >
              {data.exercises[0]!.imageSlider!.map(image => (
                <div key={image?.url} className={classes.carouselSlide}>
                  {image?.url && <img src={`${API_URL}${image.url}`} alt={image?.alternativeText || ''} />}
                </div>
              ))}
            </Carousel>
          )}

          {data.exercises[0]?.video && (
            <video
              className={classes.video}
              src={`${API_URL}${data.exercises[0].video.url}`}
              poster={`${API_URL}${data.exercises[0].videoPreviewImage?.url}`}
              controls
            />
          )}
          <div className={classes.contentContainer}>
            {data.exercises[0]?.description && (
              <div className={classes.description} dangerouslySetInnerHTML={{ __html: data.exercises[0]?.description }} />
            )}

            {currentExercise !== exercisesLastIndex && (
              <Link className={classes.link} to={`/exercises/${nextExercise}`}>
                <div className={classes.nextExerciseContainer}>
                  <span className={classes.label}>Nächste Übung</span>
                  <img className={classes.arrow} src={arrow} alt="Arrow" />
                </div>
              </Link>
            )}
          </div>
        </>
      )}
      <FetchStatus loading={loading} error={error} />
    </AppLayout>
  )
}
