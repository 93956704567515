import { IonSlide, IonCard, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react'

import './IntroSlide.scss'

interface IntroSlideProps {
  Actions: JSX.Element
  imageSrc: string
  imageAlt: string
  title: string
  subtitle?: string
}

export const IntroSlide: React.FC<IntroSlideProps> = ({ title, subtitle, imageSrc, imageAlt, Actions }) => (
  <IonSlide className="intro-slide">
    <IonCard className="intro-card fullwidth height-100">
      <IonCardContent>
        <div>
          <img src={imageSrc} className="intro-img" alt={imageAlt} />
          <IonCardTitle mode="ios" color="dark" className="intro-card-title">
            <h1>{title}</h1>
          </IonCardTitle>
          {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
          <div className="intro-footer-bar">{Actions}</div>
        </div>
      </IonCardContent>
    </IonCard>
  </IonSlide>
)
