/* eslint-disable jsx-a11y/media-has-caption */
import { IonIcon } from '@ionic/react'
import cn from 'classnames'
import { addOutline, heart, heartOutline } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useAddFavoriteToMeMutation, useIssueQuery, useRemoveFavoriteFromMeMutation } from '../../api/graphql'
import { FetchStatus } from '../../components/FetchStatus'
import { useUser } from '../../contexts/User'
import { AppLayout } from '../../layout'

import classes from './Issue.module.scss'
import { ExerciseListItem } from './components/ExerciseListItem'

export const Issue: React.FC = () => {
  const param: { id: string } = useParams()
  const { data, loading, error } = useIssueQuery({ variables: { id: param.id } })
  const { user, setUser } = useUser()
  const [descriptionOpen, setDescriptionOpen] = useState(false)
  const handleClickDescription = (): void => setDescriptionOpen(!descriptionOpen)
  const [addFavoriteToMeMutation] = useAddFavoriteToMeMutation()
  const [removeFavoriteFromMeMutation] = useRemoveFavoriteFromMeMutation()

  const API_URL = process.env.REACT_APP_API_URL

  // TODO: redirect to payment page when user is locked (except issue is not premium) & use user context for locked status

  const history = useHistory()

  const locked = !user?.premium

  const partOfPremium = data && data.issues && data?.issues[0]?.premium

  const isIssueFavorite = (id: string): boolean => {
    if (user?.favorite_issues) {
      return user.favorite_issues.map(favorite => favorite?.id).includes(id)
    } else {
      return false
    }
  }

  const handleClickFavorite = (id: string): void => {
    console.log(id)
    if (isIssueFavorite(id)) {
      removeFavoriteFromMeMutation({ variables: { id, type: 'issue' } }).then(result => {
        if (user && result.data) setUser({ ...user, favorite_issues: result.data.removeFavoriteFromMe.favorite_issues })
      })
    } else {
      addFavoriteToMeMutation({ variables: { id, type: 'issue' } }).then(result => {
        if (user && result.data) setUser({ ...user, favorite_issues: result.data.addFavoriteToMe.favorite_issues })
      })
    }
  }

  useEffect(() => {
    if (partOfPremium && locked) history.replace('/')
  }, [history, locked, partOfPremium])

  return (
    <AppLayout variant="back" bgColor="turquoise" iconColor="light" statusBarColor="light">
      {data && data.issues && data.issues.length > 0 && (
        <>
          <div className={classes.headerContainer}>
            <div className={classes.title}>{data.issues[0]?.title}</div>
            <IonIcon
              className={classes.favoriteIcon}
              icon={isIssueFavorite(param.id) ? heart : heartOutline}
              color="light"
              onClick={() => handleClickFavorite(param.id)}
            />
          </div>
          {data.issues[0]?.video && (
            <video
              className={classes.video}
              src={`${API_URL}${data.issues[0].video.url}`}
              poster={`${API_URL}${data.issues[0].videoPreviewImage?.url}`}
              controls
            />
          )}
          <div className={classes.container}>
            <div
              onClick={handleClickDescription}
              onKeyPress={handleClickDescription}
              role="button"
              tabIndex={0}
              className={cn(classes.description, { [classes.descriptionClosed]: !descriptionOpen })}
            >
              {data.issues[0]?.description}
              <IonIcon
                className={cn(classes.descriptionIcon, { [classes.descriptionIconClosed]: !descriptionOpen })}
                icon={addOutline}
                color="light"
              />
            </div>
            <div className={classes.exerciseListContainer}>
              {data.issues[0]?.exercises?.map(
                item => (item ? <ExerciseListItem key={item?.id} id={item?.id} title={item?.title} /> : null) // eslint-disable-line comma-dangle
              )}
            </div>
          </div>
        </>
      )}
      <FetchStatus loading={loading} error={error} />
    </AppLayout>
  )
}
