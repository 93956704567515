import { IonList } from '@ionic/react'
import React from 'react'

import { useRemoveFavoriteFromMeMutation } from '../../api/graphql'
import { FetchStatus } from '../../components/FetchStatus'
import { useUser } from '../../contexts/User'
import { AppLayout } from '../../layout'

import classes from './Favorites.module.scss'
import { FavoritesListItem } from './components/FavoritesListItem'

export const Favorites: React.FC = () => {
  const { user, setUser } = useUser()
  const [removeFavoriteFromMeMutation, { loading, error }] = useRemoveFavoriteFromMeMutation()

  console.log(user)

  const handleDeleteFavorite = (id: string, type: 'exercise' | 'issue'): void => {
    removeFavoriteFromMeMutation({ variables: { id, type } }).then(result => {
      if (user && result.data) {
        if (type === 'exercise') {
          setUser({ ...user, favorites: result.data.removeFavoriteFromMe.favorites })
        } else if (type === 'issue') {
          setUser({ ...user, favorite_issues: result.data.removeFavoriteFromMe.favorite_issues })
        }
      }
    })
  }

  return (
    <AppLayout variant="menu" bgColor="pink" iconColor="light" statusBarColor="light">
      <div className={classes.listContainer}>
        <h1 className={classes.title}>Favoriten</h1>
        {user && user.favorites && user?.favorites?.length > 0 && <h2 className={classes.subtitle}>Übungen</h2>}
        <IonList className={classes.ionList}>
          {user?.favorites?.map(
            fav =>
              fav && (
                <FavoritesListItem
                  key={fav.id}
                  id={fav.id}
                  title={fav.title}
                  issues={fav.issues}
                  onFavoriteDelete={id => handleDeleteFavorite(id, 'exercise')}
                  type="exercise"
                />
              ) // eslint-disable-line comma-dangle
          )}
        </IonList>
        {user && user.favorite_issues && user?.favorite_issues?.length > 0 && <h2 className={classes.subtitle}>Beschwerden</h2>}
        <IonList className={classes.ionList}>
          {user?.favorite_issues?.map(
            fav =>
              fav && (
                <FavoritesListItem
                  key={fav.id}
                  id={fav.id}
                  title={fav.title}
                  issues={[]}
                  onFavoriteDelete={id => handleDeleteFavorite(id, 'issue')}
                  type="issue"
                />
              ) // eslint-disable-line comma-dangle
          )}
        </IonList>
      </div>
      <FetchStatus loading={loading} error={error} />
    </AppLayout>
  )
}
