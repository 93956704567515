import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonSpinner,
  useIonAlert,
  useIonToast,
} from '@ionic/react'
import { keyOutline } from 'ionicons/icons'
import { useState } from 'react'

import { useForgotPasswordMutation } from '../../api/graphql'

import classes from './ForgotPassword.module.scss'

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>()
  const [showToast] = useIonToast()
  const [showAlert] = useIonAlert()

  const [forogtPassword, { loading }] = useForgotPasswordMutation({
    variables: {
      email: email!,
    },
  })

  const handleForgotPassword = (): void => {
    showAlert({
      header: 'Passwort zurücksetzen',
      message: 'Sind sie sicher, dass Sie ihr Passwort zurücksetzen möchten?',
      buttons: [
        'Abbrechen',
        {
          text: 'Zurücksetzen',
          handler: () => {
            forogtPassword()
              .then(() => {
                showToast('Passwort wurde zurüpckgesetzt. Bitte prüfen Sie Ihre Emails.', 10000)
              })
              .catch((e: string) => {
                showToast('Leider konnte das Passwort nicht zurückgesetzt werden', 5000)
                console.error(e)
              })
          },
        },
      ],
    })
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className={classes.bg}>
          <div className={classes.spacer} />
          <div className={classes.body}>
            <>
              <h1>
                <IonIcon icon={keyOutline} className={classes.icon} />
                <br />
                Passwort
                <br />
                zurücksetzen
                <br />
              </h1>
              <p className={classes.text}>
                Du kennst dein Password? <IonRouterLink>Einloggen</IonRouterLink>
              </p>
              <div>
                <IonItem className={classes.item} color="none">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput disabled={loading} type="email" value={email} onIonChange={e => setEmail(e.detail.value!)} />
                </IonItem>
              </div>
              <IonButton color="primary" className={classes.btn} onClick={() => handleForgotPassword()}>
                {loading ? (
                  <IonSpinner name="crescent" />
                ) : (
                  <>
                    <span className={classes.btnText}>Passwort zurücksetzen</span>
                  </>
                )}
              </IonButton>
            </>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}
