import { ApolloError } from '@apollo/client'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { BodypartsQuery, useBodypartsQuery } from '../../api/graphql'

type BodyPartsProviderProps = {
  bodyParts: BodypartsQuery['bodyParts'] | null
  loading: boolean
  error: ApolloError | undefined
}

const BodyPartsContext = createContext<BodyPartsProviderProps>({ bodyParts: [], loading: true, error: undefined })

export const BodyPartsProvider: React.FC = ({ children }) => {
  const location = useLocation()

  const [bodyParts, setBodyParts] = useState<BodypartsQuery['bodyParts'] | null>(null)

  const { data, loading, error } = useBodypartsQuery({ skip: location.pathname === '/login' })

  useEffect(() => {
    if (data) setBodyParts(data.bodyParts)
  }, [data, bodyParts])

  const value = {
    bodyParts,
    loading,
    error,
  }

  return <BodyPartsContext.Provider value={value}>{children}</BodyPartsContext.Provider>
}

export const useBodyParts = (): BodyPartsProviderProps => useContext(BodyPartsContext)
