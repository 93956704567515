/* eslint-disable @typescript-eslint/no-empty-function */
import { useIonRouter } from '@ionic/react'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { LoginMutation } from '../../api/graphql'
import ROUTES from '../../routes'
import { useStorage } from '../Storage'

export type ContextUser = LoginMutation['login']['user'] & { premium?: boolean }

interface UserProviderProps {
  jwt: string | undefined
  user: ContextUser | undefined
  setUser: (user: ContextUser | any) => void
  setJwt: (jwt: string) => void
  logout: () => void
  login: (jwt: string, user: ContextUser) => void
}

const UserContext = createContext<UserProviderProps>({
  user: undefined,
  jwt: undefined,
  setJwt: () => {},
  setUser: () => {},
  logout: () => {},
  login: () => {},
})

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<UserProviderProps['user']>()
  const [jwt, setJwt] = useState<UserProviderProps['jwt']>()
  const { storage } = useStorage()
  const router = useIonRouter()

  const authRequired = (path: string): boolean => !!ROUTES.find(route => route.path === path)?.authorized

  const isPremium = (role: ContextUser['role']): boolean => role?.type === 'premium'

  // handles restore user when reloading
  useEffect(() => {
    const restore = async (): Promise<void> => {
      const token = await storage?.get('token')
      if (token) {
        setJwt(token)
      }
      const user = await storage?.get('user')
      if (user) {
        setUser({
          ...user,
          premium: isPremium(user.role),
        })
      }
    }
    restore()
  }, [storage]) // eslint-disable-line react-hooks/exhaustive-deps

  const login = async (token: UserProviderProps['jwt'], userData: UserProviderProps['user']): Promise<void> => {
    console.log('Action: Login')
    setJwt(token)
    await storage?.set('token', token)
    if (userData) {
      setUser({
        ...userData,
        premium: isPremium(userData.role),
      })
    }
    await storage?.set('user', userData)
  }

  const updateUserInStorage = useCallback(
    async (userData: UserProviderProps['user']): Promise<void> => {
      await storage?.set('user', userData)
    },
    [storage] // eslint-disable-line comma-dangle
  )

  useEffect(() => {
    if (user) {
      console.log('Update: User in storage')
      updateUserInStorage(user)
    }
  }, [updateUserInStorage, user])

  // handles redirect to `/login` when jwt or user not set
  useEffect(() => {
    const requestedRoute = router.routeInfo.pathname
    const handleReload = async (): Promise<void> => {
      if (storage && authRequired(requestedRoute) && !user) {
        const token = await storage?.get('token')
        const user = await storage?.get('user')

        if (token && user) {
          console.log('Action: Login user')
          login(token, user)
        } else {
          console.log('Action: Redirect to login')
          router.push('/login') // eslint-disable-line fp/no-mutating-methods
        }
      }
    }
    handleReload()
  }, [user, storage]) // eslint-disable-line react-hooks/exhaustive-deps

  const logout = async (): Promise<void> => {
    console.log('Action: Logout')
    setJwt(undefined)
    await storage?.remove('token')
    setUser(undefined)
    await storage?.remove('user')
  }

  const value = {
    user,
    jwt,
    setUser,
    setJwt,
    logout,
    login,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = (): UserProviderProps => useContext(UserContext)
