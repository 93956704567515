import { menuController } from '@ionic/core'
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle } from '@ionic/react'
import { heartOutline, logOutOutline, personCircleOutline, informationCircleOutline, bodyOutline } from 'ionicons/icons'
import { useLocation } from 'react-router-dom'

import './Menu.css'
import { useUser } from '../contexts/User'

interface AppPage {
  url: string
  iosIcon: string
  mdIcon: string
  title: string
}

const appPages: Array<AppPage> = [
  {
    title: 'Übersicht',
    url: '/',
    iosIcon: bodyOutline,
    mdIcon: bodyOutline,
  },
  {
    title: 'Favoriten',
    url: '/favorites',
    iosIcon: heartOutline,
    mdIcon: heartOutline,
  },
  {
    title: 'Account',
    url: '/profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline,
  },
]

const otherPages: Array<AppPage> = [
  {
    title: 'Über diese App',
    url: '/about',
    iosIcon: informationCircleOutline,
    mdIcon: informationCircleOutline,
  },
]

const Menu: React.FC = () => {
  const location = useLocation()
  const { user, logout } = useUser()

  const handleLogout = (): void => {
    logout()
    menuController.close()
  }

  return (
    <IonMenu contentId="main" type="overlay" menuId="test">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader className="ion-menu-header">{user?.email}</IonListHeader>
          {appPages.map((appPage, index) => (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={location.pathname === appPage.url ? 'selected' : ''}
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                <IonLabel>{appPage.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>Weiteres</IonListHeader>
          {otherPages.map((appPage, index) => (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem
                className={location.pathname === appPage.url ? 'selected' : ''}
                routerLink={appPage.url}
                routerDirection="none"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                <IonLabel>{appPage.title}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          ))}
          <IonItem routerDirection="none" lines="none" detail={false}>
            <IonIcon slot="start" ios={logOutOutline} md={logOutOutline} />
            <IonLabel onClick={() => handleLogout()}>Logout</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default Menu
