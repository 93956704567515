import { IonIcon } from '@ionic/react'
import { Link } from 'react-router-dom'

import { Exercise } from '../../../api/graphql'
import arrow from '../../../common/icons/arrow.svg'

import classes from './ExerciseListItem.module.scss'

type ExerciseListItemProps = Pick<Exercise, 'id' | 'title'>

export const ExerciseListItem: React.FC<ExerciseListItemProps> = ({ id, title }) => (
  <Link className={classes.link} to={`/exercises/${id}`}>
    <div className={classes.item}>
      <span className={classes.itemTitle}>{title}</span>
      <IonIcon className={classes.iconArrow} src={arrow} />
    </div>
  </Link>
)
