/* eslint-disable fp/no-mutating-methods */
import cn from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { BodyPart as BodyPartProps } from '../../api/graphql' // eslint-disable-line @typescript-eslint/no-unused-vars-experimental
import { useBodyParts } from '../../contexts/BodyPartsProvider'
import { useUser } from '../../contexts/User'
import { AppLayout } from '../../layout'

import classes from './BodyPart.module.scss'
import { IssueListItem } from './components/IssueListItem'

export const BodyPart: React.FC = () => {
  const query = useLocation().search
  const bodyPartId = new URLSearchParams(query).get('id')
  const history = useHistory()
  const { user } = useUser()

  const { bodyParts } = useBodyParts()

  const [activeTab, setActiveTab] = useState<BodyPartProps['id'] | null>(null)

  const refScrollContainer = useRef<HTMLDivElement | null>(null)
  const refActiveTab = useRef<HTMLDivElement | null>(null)

  const handleClickTab = async (key: BodyPartProps['id']): Promise<void> => {
    setActiveTab(key)
    history.replace(`/bodyparts?id=${key}`)
  }
  const handleScroll = (): void => {
    const scrollX = refActiveTab.current?.offsetLeft ? refActiveTab.current?.offsetLeft - 40 : 0
    refScrollContainer.current?.scrollTo(scrollX, 0)
  }

  // sets first tab to active when data is loaded
  useEffect(() => {
    if (activeTab === null && !bodyPartId && bodyParts && bodyParts.length > 0) {
      setActiveTab(bodyParts[0]!.id)
      handleScroll()
    } else if (bodyPartId) {
      setActiveTab(bodyPartId)
      handleScroll()
    }
  }, [bodyParts, bodyPartId, activeTab])

  const bgColor = bodyParts?.find(part => part?.id === activeTab)?.bgColor

  return (
    <AppLayout variant="menu" bgColor={bgColor || undefined} iconColor="light">
      <div ref={refScrollContainer} className={classes.tabContainer}>
        {bodyParts?.map(
          part =>
            part ? (
              <div
                key={part.id}
                ref={part.id === activeTab ? refActiveTab : null}
                className={cn(classes.tab, { [classes.activeTab]: part.id === activeTab })}
                onClick={() => {
                  handleClickTab(part.id).then(handleScroll)
                }}
                onKeyPress={() => {
                  handleClickTab(part.id).then(handleScroll)
                }}
                role="button"
                tabIndex={0}
              >
                {part.title}
              </div>
            ) : null // eslint-disable-line comma-dangle
        )}
      </div>
      <div className={classes.issueListContainer}>
        {bodyParts
          ?.find(activePart => activePart?.id === activeTab)
          ?.issues?.map(issue => {
            const countOfExercises = issue?.exercises?.length
            return issue ? (
              <IssueListItem
                key={issue.id}
                id={issue.id}
                title={issue.title}
                premium={issue.premium}
                countOfExercises={countOfExercises}
                // TODO: add user payment status from user context (locked = not paid)
                locked={!user?.premium}
              />
            ) : null // eslint-disable-line comma-dangle
          })}
      </div>
    </AppLayout>
  )
}
