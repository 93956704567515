import { IonSlide, isPlatform } from '@ionic/react'

import './CarouselSlide.scss'

interface CarouselSlideProps {
  imageSrc?: string | undefined
  imageAlt?: string | undefined
  description?: string | undefined
}

export const CarouselSlide: React.FC<CarouselSlideProps> = ({ imageSrc, imageAlt, description }) => (
  <IonSlide className="carousel-slide">
    {isPlatform('capacitor') && <span className="gradient gradient--top" />}
    {imageSrc && <img src={imageSrc} alt={imageAlt || imageSrc} />}
    {description && <span className="carousel-slide-description gradient gradient--bottom">{description}</span>}
  </IonSlide>
)
