import { StatusBar, Style } from '@capacitor/status-bar'
import { IonButton, IonContent, IonIcon, isPlatform } from '@ionic/react'
import { useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { SafeArea } from '../../common/components'
import closeIcon from '../../common/icons/close_premium.svg'
import { sliderImages } from '../../fixtures/PREMIUMPAGE'

import classes from './Premium.module.scss'
import { CarouselSlide } from './components/CarouselSlide'

export const Premium: React.FC<{
  onDismiss: () => void
}> = ({ onDismiss }) => {
  const hideModal = (): void => {
    onDismiss()
    if (isPlatform('capacitor')) {
      StatusBar.setStyle({ style: Style.Light })
    }
  }

  useEffect(() => {
    if (isPlatform('capacitor')) {
      StatusBar.setStyle({ style: Style.Dark })
    }
  }, [])

  return (
    <IonContent>
      <SafeArea className={classes.wrapper} top={false}>
        <IonIcon className={classes.iconClose} src={closeIcon} onClick={hideModal} />
        {/* TODO: add correct fixtures */}
        <Carousel className={classes.carouselWrapper} showStatus={false} showArrows={false} showThumbs={false} autoPlay={false}>
          {sliderImages.map(image => (
            <CarouselSlide key={image.src} imageSrc={image.src} imageAlt={image.alt} description={image.description} />
          ))}
        </Carousel>
        <div className={classes.card}>
          {/* TODO: add correct fixtures */}
          <div className={classes.title}>Jetzt Premium-Version freischalten!</div>
          <p>Schalten Sie alle knapp 150 HD Videos für alle Beschwerdebilder frei.</p>
          <div className={classes.prices}>
            <IonButton className={classes.priceButton} color="primary" expand="block" href="/checkout/month">
              2,99€ /
              <br />
              Monat
            </IonButton>
            {/* <div style={{ textAlign: 'center' }}>oder</div> */}
            <IonButton className={classes.priceButton} color="primary" expand="block" href="/checkout/year">
              29,99€ /
              <br />
              Jahr
            </IonButton>
          </div>
        </div>
        {/* TODO: add correct fixtures */}
        <div className={classes.text}>
          Das Abo wird auf die gewählte Dauert abgeschlossen, kann jedoch jederzeit zum Ende der Laufzeit gekündigt werden. In
          diesem Fall wird die Kündigung vorgemerkt und automatisch für Sie zu nächst möglichen Zeitpunkt beendet. Wird das Abo
          nicht innerhalb der Laufzeit gekündigt, verlängert es sich automatisch um die gewählte Laufzeit.
          <br />
          Die Zahlung wird über unseren Partner Stripe abgewickelt. Bei Abschluss bekennen Sie sich einverstanden, Ihre Daten -
          die zur Zahlungsabwicklung nötig sind - an Stripe zu übertragen. Ihre personenbezogenen Daten können Sie jederzeit bei
          uns löschen lassen. Wir behabndeln Ihre Daten sehr vertrauensvoll und geben diese nicht anderweitig weiter.
        </div>
      </SafeArea>
    </IonContent>
  )
}
