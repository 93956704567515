import { IonPage, IonSlides, IonContent, IonText } from '@ionic/react'
import { useState } from 'react'

import { SafeArea } from '../../common/components'
import { useStorage } from '../../contexts/Storage'

import { IntroSlide } from './components/IntroSlide/IntroSlide'

import './Introduction.scss'

export const Introduction: React.FC = () => {
  const [swiper, setSwiper] = useState<any>({})
  const { storage } = useStorage()

  const swipeNext = (): void => {
    swiper.slideNext()
  }

  const swipePrev = (): void => {
    swiper.slidePrev()
  }

  const init = async function (this: any): Promise<void> {
    // eslint-disable-next-line fp/no-this
    setSwiper(await this.getSwiper())
  }

  const skipIntro = (): void => {
    storage?.set('skipIntro', true).then(() => {
      // TODO: IonRouter will not refresh page when pushing '/'?!
      // eslint-disable-next-line fp/no-mutation
      window.location.href = '/'
    })
  }

  return (
    <IonPage>
      <IonContent color="light" fullscreen className="intro" scroll-y="false">
        <SafeArea style={{ height: '100%' }}>
          <div className="slider-container">
            <div className="intro-head">
              <h2>So funktionierts</h2>
            </div>
            <div>
              <IonSlides onIonSlidesDidLoad={init} mode="ios" className="intro-slider" pager>
                <IntroSlide
                  title="Körperbereich"
                  subtitle="Wähle deinen Bereich aus sieben vorgeschlagenen Körperregionen aus."
                  imageSrc="/assets/intro/step01.png"
                  imageAlt="intro-1"
                  Actions={
                    <>
                      <div>
                        <IonText onClick={skipIntro}>Überspringen</IonText>
                      </div>
                      <div>
                        <IonText color="dark" onClick={swipeNext}>
                          <strong>Weiter</strong>
                        </IonText>
                      </div>
                    </>
                  }
                />
                <IntroSlide
                  title="Beschwerde"
                  subtitle="Wähle eine Beschwerde aus diesem Körperbereich. Es sind eine Vielzahl an
                  Alltagsbeschwerden zu finden, zu den es detaillierte Übungen in Video und
                  anschaulichen Schritt-für-Schritt Darstellungen gibt."
                  imageSrc="/assets/intro/step02.png"
                  imageAlt="intro-2"
                  Actions={
                    <>
                      <div>
                        <IonText onClick={swipePrev}>Zurück</IonText>
                      </div>
                      <div>
                        <IonText color="dark" onClick={swipeNext}>
                          <strong>Weiter</strong>
                        </IonText>
                      </div>
                    </>
                  }
                />
                <IntroSlide
                  title="Übungen"
                  subtitle="Durch Auswahl einer Beschwerde, gelangst du zu den jeweiligen Übungen.
                  Hier stehen verscheidene Übungen mit Text, Bild und Videos dargestellt, die dir bei deinen persönlichen Beschwerden helfen."
                  imageSrc="/assets/intro/step03.png"
                  imageAlt="intro-3"
                  Actions={
                    <>
                      <div>
                        <IonText onClick={swipePrev}>Zurück</IonText>
                      </div>
                      <div>
                        <IonText color="dark" onClick={swipeNext}>
                          <strong>Weiter</strong>
                        </IonText>
                      </div>
                    </>
                  }
                />
                <IntroSlide
                  title="Favoriten"
                  subtitle="Stelle für deine persönliche Beschwerde individuelle Übungen als Favoriten zusammen, um schnell deine Übungen zu finden."
                  imageSrc="/assets/intro/step05.png"
                  imageAlt="intro-5"
                  Actions={
                    <>
                      <div>
                        <IonText onClick={swipePrev}>Zurück</IonText>
                      </div>
                      <div>
                        <IonText onClick={skipIntro}>
                          <strong>Zur App!</strong>
                        </IonText>
                      </div>
                    </>
                  }
                />
              </IonSlides>
            </div>
          </div>
        </SafeArea>
      </IonContent>
    </IonPage>
  )
}
