/* eslint-disable comma-dangle */
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import { IonReactRouter } from '@ionic/react-router'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { StoreProvider } from './contexts/Storage'
import { UserProvider } from './contexts/User'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
    <IonReactRouter>
      <StoreProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </StoreProvider>
    </IonReactRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
