export const sliderImages = [
  {
    src: '/assets/premium/unsplash1.jpg',
    alt: 'Placeholder',
    description: 'Knapp 150 Übungen mit Videos in HD',
  },
  {
    src: '/assets/premium/unsplash2.jpg',
    alt: 'Placeholder',
    description: 'Behanldung von knapp 60 Beschwerden',
  },
  {
    src: '/assets/premium/unsplash2.jpg',
    alt: 'Placeholder',
    description: 'Durch professionelle Coaches entwickelt',
  },
]
