import { Storage as IonStorage, Storage as IonStorageType, Drivers } from '@ionic/storage'
import { createContext, useContext, useEffect, useState } from 'react'

interface StorageProps {
  storage: IonStorageType | undefined
}

const StoreContext = createContext<StorageProps>({
  storage: undefined,
})

export const StoreProvider: React.FC = ({ children }) => {
  const [storage, setStorage] = useState<IonStorageType>()

  const createStorage = async (): Promise<void> => {
    const store = new IonStorage({
      name: '__slmydb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    })
    const s = await store.create()
    setStorage(s)
  }

  useEffect(() => {
    if (!storage) {
      createStorage()
    }
  })

  const value = {
    storage,
  }

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStorage = (): StorageProps => useContext(StoreContext)
