import { AboutQuery, AboutDocument } from '..'
import { initializeApollo, ApolloQueryResult } from '../../../common/graphql'

export const getAbout = async (): Promise<ApolloQueryResult<AboutQuery>> => {
  const apolloClient = initializeApollo()

  return apolloClient.query({
    query: AboutDocument,
  })
}
