import { IonButton, IonIcon } from '@ionic/react'
import { checkmarkCircleOutline } from 'ionicons/icons'

import classes from './CheckoutSuccess.module.scss'

export const CheckoutSuccess: React.FC = () => (
  <div className={classes.successWrapper}>
    <div className={classes.card}>
      <IonIcon className={classes.successIcon} icon={checkmarkCircleOutline} />
      <h1>Erfolgreich!</h1>
      <p className={classes.description}>
        Wir haben deine Zahlung erfolgreich entgegengenommen und schalten in Kürze für dich die Premium-Funktionen frei!
      </p>
      <IonButton href="/">Übersicht</IonButton>
    </div>
  </div>
)
